import React from 'react';
import { AppBar, Toolbar, Divider } from '@mui/material';
/* import Main from './Main';
import LogosOuterContainer from './LogosOuterContainer';
import LogosInnerContainer from './LogosInnerContainer';
import NewIdea from './NewIdea';
import RotatingGallery from './RotatingGallery';
import Skills from './Skills'; */
import UnderConstruction from './UnderConstruction';

import Contact from './Contact';
import Layout from './Layout';
import Footer from './Footer';

const App = () => {

  return (
    <>
      <AppBar position="sticky" color="primary" component="header">
        <Toolbar>
          <img src="/assets/banner.png" height={30} alt="iSolid Ltd" />
        </Toolbar>
      </AppBar>
{/*       <UnderConstruction>
        <Divider mt={10} mb={10} />
        <Contact />
      </UnderConstruction> */}
      <Layout />
      <Footer />
    </>
  );
};

export default App;
