import React, { memo } from 'react';
import { AppBar, Toolbar, Typography, Hidden } from '@mui/material';

const Footer = () => (
  <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, marginTop: 4 }} component="footer">
    <Toolbar variant="dense" component="p">
      <Typography variant="caption" component="small" sx={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', lineHeight: 1, fontSize: 'smaller' }}>
        Registered No: 08556624
        <br />
        Registered Address: 35 Station Approach,
        <Hidden smDown>
          &nbsp;
        </Hidden>
        <Hidden smUp>
          <br />
        </Hidden>
        West Byfleet, Surrey, England, KT14 6NF
      </Typography>
    </Toolbar>
  </AppBar>
);

export default memo(Footer);
