import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const UnderConstruction = ({ children }) => (
  <Box component="main" pt={8} sx={{ minHeight: '100%', maxWidth: 1280, marginLeft: 'auto', marginRight: 'auto' }}>
    <Box
      component="img"
      src="/assets/under_construction.jpeg"
      alt="Website Under Construction"
      width="100%"
      sx={{
        width: '100%',
        boxShadow: 2,
      }}
    />
    { children }
  </Box>
);

UnderConstruction.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
};

export default UnderConstruction;
