/* require('../assets/agile.jpeg');
require('../assets/azure.png');
require('../assets/azure_2.png');
require('../assets/banner.png');
require('../assets/bootstrap.jpeg');
require('../assets/c_sharp.jpeg');
require('../assets/docker.jpeg'); */
require('../assets/banner.png');
require('../assets/favicon.ico');
/* require('../assets/javascript.jpeg');
require('../assets/javascript_2.jpeg');
require('../assets/json.jpeg');
require('../assets/knockout.jpeg');
require('../assets/material_ui.jpeg');
require('../assets/mongo.jpeg');
require('../assets/mvc.jpeg');
require('../assets/node.jpeg');
require('../assets/react.jpeg');
require('../assets/react_2.jpeg');
require('../assets/sql_server.jpeg');
require('../assets/visual_studio.jpeg');
require('../assets/visual_studio_2.jpeg'); */
require('../assets/under_construction.jpeg');
