import React, { useEffect, useState, useCallback, memo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Grid, Hidden, useTheme, Box, Typography, TextField, Stack, Button, Collapse, Alert, IconButton  } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SUBMITTED, RESET_SUBMITTED } from '../reducers/actions';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  company: Yup.string(),
  email: Yup.string().required('Email is required').email('Must be a valid email'),
  phone: Yup.string().required('Phone Number is required'),
  message: Yup.string().required('Message is required'),
});

const Layout = () => {

  const theme = useTheme();
  const displayRow = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const submitted = useSelector(({ enquirySubmitted }) => enquirySubmitted);
  const [error, setError]  = useState(null);
  const setSubmitted = useCallback(() => dispatch({ type: SET_SUBMITTED, payload: true }), []);
  const resetSubmitted = useCallback(() => dispatch({ type: RESET_SUBMITTED, payload: false }), []);

  const {
    values: {
      name,
      company,
      email,
      phone,
      message,
    },
    handleChange,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
      company: '',
      email: '',
      phone: '',
      message: '',
    },
    validationSchema,
    onSubmit: async (v) => {
      try {
        setSubmitted(false);
       
        const recaptchaToken = await grecaptcha.execute('6LcC-V8pAAAAAKUzTljNQUafzlYH5wQa7oZRhY6r', { action: 'submit'})

        const r = await fetch('/api/contact', {
          method: 'POST',
          body: JSON.stringify({
            ...v,
            recaptchaToken,
          }),
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if(r.ok) {
          setSubmitted(true)
        } else {
          setError(new Error(r.statusText));
        }
      } catch (error) {
        setError(error);
      }

    },
  });

  useEffect(() => {
    resetForm({
      name: '',
      company: '',
      email: '',
      phone: '',
      message: '',
    });
    if (submitted) {
      setError(null)
    }
  }, [submitted]);

  const errorText = (field) => touched[field] ? errors[field] || '' : '';

  const handleBlur = async (e) => {

    const { target: { name: fieldName, value } } = e;

    if (typeof value === 'string') {
      let patched = value?.trim()
      patched = patched.replace(/(\. *)$/gm, '');

      if(fieldName === 'email') {
        patched = patched.toLowerCase();
      }

      await setFieldValue(fieldName, patched);
      await setFieldTouched(fieldName, true, true);
    }
  }
  return (
    <>
      <Collapse in={submitted}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={resetSubmitted}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 1, mt: 2 }}
        >
          Your message has been sent successfully.
        </Alert>
      </Collapse>
      <Collapse in={!!error}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          color="error"
          sx={{ mb: 1, mt: 2 }}
        >
          There was an error sending your message.
        </Alert>
      </Collapse>
      <Box
        sx={({
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: 1920,
          paddingBottom: 8,
        })}
      component="main"
    >
      <Hidden mdDown>
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center',
            marginBottom: theme.spacing(1),
            paddingTop: theme.spacing(4)
          }}
          >
            Website Under Construction
          </Typography>
      </Hidden>
      <Grid
        container
        direction={ displayRow ? 'row' : 'column'}
        justifyContent="space-between"
        alignItems={ displayRow ? 'flex-start' : 'flex-start'}
      >
        <Grid item component="section" xs={8}>
          <Box sx={{
            maxWidth: '100%',
            marginLeft: {  
              xs: 0,
              sm: 0,
              md: 2,
            },
            marginTop: displayRow ? 2.5 : 0
          }}>
            <Box
              component="img"
              src="/assets/under_construction.jpeg"
              alt="Website Under Construction"
              width="100%"
              sx={{
                boxShadow: 2,
              }}
            />
          </Box>
        </Grid>
        <Grid item component="section" xs={4} container direction="column">
          <Grid item sx={{ margin: 2 }}>
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Typography
                  variant="h6"
                  color="primary"
                  paddingTop={0}
                  marginTop={0}
                >
                  Stay in Touch
                </Typography>
                <TextField
                  label="Name"
                  fullWidth
                  name="name"
                  autoFocus
                  value={name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errorText('name')} 
                  helperText={errorText('name')}
                />
                <TextField
                  label="Company"
                  fullWidth
                  name="company"
                  value={company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errorText('company')} 
                  helperText={errorText('company')}
                />
                <TextField
                  label="Email"
                  fullWidth
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errorText('email')} 
                  helperText={errorText('email')}
                />
                <TextField
                  label="Phone Number"
                  fullWidth
                  type="tel"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errorText('phone')} 
                  helperText={errorText('phone')}
                />
                <TextField
                  label="Message"
                  name="message"
                  fullWidth
                  rows={5}
                  multiline
                  value={message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errorText('message')} 
                  helperText={errorText('message')}
                />
              </Stack>
              <Box sx={{ marginTop: 2 }}>
                <Button type="submit" variant="outlined">Submit</Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </>
  )
};

export default memo(Layout);
