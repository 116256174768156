import ReactDOM from 'react-dom/client';
import * as React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import createCache from '@emotion/cache';
import reducers from '../reducers';
import Components from '../components';
// eslint-disable-next-line no-unused-vars
import Assets from './assets';



import styles from './styles.css';

const emotionCache = createCache({ key: 'css' });

const store = configureStore({
  reducer: reducers,
  preloadedState: window.__PRELOADED_STATE__,
});

const Client = () => (
  <CacheProvider value={emotionCache}>
    <Provider store={store}>
      <BrowserRouter>
        <Components />
      </BrowserRouter>
    </Provider>
  </CacheProvider>
);

delete window.__PRELOADED_STATE__;

hydrateRoot(document.getElementById('root'), <Client />);
