import { SET_SUBMITTED, RESET_SUBMITTED } from "./actions";

const reducer = (state = false, { type: actionType }) => {
  switch (actionType) {
  case SET_SUBMITTED:
    return true;
  case RESET_SUBMITTED:
    return false;
  default:
    return state;
  }
};

export default reducer;
