import { combineReducers } from "@reduxjs/toolkit";
import enquirySubmitted from './enquirySubmittedReducer';

const reducers = combineReducers({
  enquirySubmitted,
});

export const initialState = {
  enquirySubmitted: false,
};

export default reducers;
